<template>
  <div class="page-main">
    <div class="role-name">
      <span>岗位名称：</span>
      <b class="c-green m-r-20">{{ roleObj.name }}</b>
      <el-checkbox v-model="isCheckAll" title="全选" @change="checkAll">全选</el-checkbox>
    </div>
    <!-- 表格 -->
    <vxe-grid
      class="m-b-10"
      size="mini"
      border
      highlight-hover-row
      :data="listData"
      :columns="tableColumn"
    >
      <template #default_st="{ row }">
        <el-checkbox v-model="row.assigned" :title="row.title" @change="checkList(row)">
          {{ row.title }}
        </el-checkbox>
      </template>
      <template #default_nd="{ row }">
        <div class="group-list">
          <div v-for="(item, index) in row.children" :key="index" class="group-item">
            <el-checkbox
              v-model="item.assigned"
              :title="item.remark"
              @change="checkItem(row, item)"
            >
              {{ item.remark }}
            </el-checkbox>
          </div>
        </div>
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCheckAll: false,
      role_id: '',
      roleObj: {},
      listData: [],
      tableColumn: [
        {
          title: '模块',
          width: 200,
          slots: {
            default: 'default_st'
          }
        },
        {
          title: '权限',
          slots: {
            default: 'default_nd'
          }
        }
      ]
    }
  },
  created() {
    this.role_id = this.$route.query.id
    this.getListData()
  },
  methods: {
    // 获取权限数据列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/role/list',
        data: {
          id: this.role_id
        }
      }).then((res) => {
        if (res.status === 200) {
          this.roleObj = this.$util.deepCopy(res.data.role)
          let list = this.$util.deepCopy(res.data.data)
          this.listData = list.map((itemSt) => {
            let result = {
              ...itemSt,
              assigned: itemSt.children.every((itemNd) => itemNd.assigned)
            }
            return result
          })
          this.initIsCheckAll()
        }
      })
    },
    // 初始化是否全选状态
    initIsCheckAll() {
      this.isCheckAll = this.listData.every((item) => item.assigned)
    },
    // 选择-全部
    checkAll() {
      let rowIds = []
      this.listData.forEach((itemSt) => {
        itemSt.assigned = this.isCheckAll
        itemSt.children.forEach((itemNd) => {
          if (itemNd.assigned !== this.isCheckAll) rowIds.push(itemNd.id) // 需要更新权限的id
          itemNd.assigned = this.isCheckAll
        })
      })
      this.batchUpdateCheck(this.isCheckAll, rowIds)
    },
    // 选择-列
    checkList(row) {
      let rowIds = []
      row.children.forEach((item) => {
        if (item.assigned !== row.assigned) rowIds.push(item.id) // 需要更新权限的id
        item.assigned = row.assigned
      })
      this.batchUpdateCheck(row.assigned, rowIds)
      this.initIsCheckAll()
    },
    // 批量更新选中
    batchUpdateCheck(bol, list) {
      this.$api({
        method: 'post',
        url: '/admin/role/assignPermissionSwitchListVue',
        data: {
          role_id: this.role_id,
          checked: bol,
          assignedList: list
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 选择-项
    checkItem(row, item) {
      row.assigned = row.children.every((item) => item.assigned)
      this.initIsCheckAll()
      this.$api({
        method: 'post',
        url: '/admin/role/assignPermissionSwitchVue',
        params: {
          role_id: this.role_id,
          permission_id: item.id,
          checked: item.assigned
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  .role-name {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    background: @colorGrayF;
    margin-bottom: 10px;
  }
  .group-list {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    .group-item {
      margin-right: 20px;
      line-height: 20px;
      white-space: nowrap;
    }
  }
  /deep/ .vxe-table--render-default.size--mini .vxe-body--column:not(.col--ellipsis) {
    height: 30px;
    padding: 0;
  }
  /deep/ .el-checkbox {
    font-weight: normal;
    .el-checkbox__label {
      padding-left: 2px;
      font-size: 12px;
    }
  }
}
</style>
